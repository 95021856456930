import validation from '@dmm/lib-common/lib/validation';
import _ from 'lodash';

// AB test only, after the validation will be done via a tool (most likely Twilio)
const isValidPhoneField = (value) => {
  const MAX_LENGTH_NUMBER = 15; // the ITU-T E.164 recommendation
  const trimmedValue = value.trim();
  const digitsOnly = trimmedValue.replace(/[-\s]/g, '');
  if (trimmedValue !== '' && !isNaN(digitsOnly) && digitsOnly.length <= MAX_LENGTH_NUMBER) {
    return true;
  }
  return false;
};

export default function validate(val, type) {
  let pass = true;
  switch (type) {
  case 'text':
    pass = !_.isEmpty(val);
    break;
  case 'name':
    pass = validation.isValidName(val);
    break;
  case 'fullName':
    pass = validation.isValidFirstSecondName(val);
    break;
  case 'email':
    pass = (validation.isValidEmail(val));
    break;
  case 'phone':
    pass = true;
    break;
  case 'phoneField':
    pass = isValidPhoneField(val);
    break;
  case 'phoneNumber':
    pass = validation.isValidPhoneNumber(val);
    break;
  case 'zip':
    pass = validation.isValidUsZipCode(val);
    break;
  case 'country':
    pass = !_.isEmpty(_.trim(val));
    break;
  default:
    break;
  }
  return pass;
}
