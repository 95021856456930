import { getMessages } from '../tppServices/translations/messages';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';

export const getCountries = (m) => {
  const result = {};

  Object.entries(m).forEach(([key, value]) => {
    if (key.startsWith('app.countries.')) {
      const parts = key.split('.');
      if (value !== undefined) {
        result[parts[2]] = value;
      }
    }
  });

  const sorted = Object.entries(result).sort((a, b) => a[1].localeCompare(b[1]));
  return Object.fromEntries(sorted);
};

const countryPrefixes = [
  {
    'code': 'AL',
    'name': 'Albania',
    'prefix': 355
  },
  {
    'code': 'DZ',
    'name': 'Algeria',
    'prefix': 213
  },
  {
    'code': 'AF',
    'name': 'Afghanistan',
    'prefix': 93
  },
  {
    'code': 'AR',
    'name': 'Argentina',
    'prefix': 54
  },
  {
    'code': 'AE',
    'name': 'United Arab Emirates',
    'prefix': 971
  },
  {
    'code': 'AW',
    'name': 'Aruba',
    'prefix': 297
  },
  {
    'code': 'OM',
    'name': 'Oman',
    'prefix': 968
  },
  {
    'code': 'AZ',
    'name': 'Azerbaijan',
    'prefix': 994
  },
  {
    'code': 'EG',
    'name': 'Egypt',
    'prefix': 20
  },
  {
    'code': 'ET',
    'name': 'Ethiopia',
    'prefix': 251
  },
  {
    'code': 'IE',
    'name': 'Ireland',
    'prefix': 353
  },
  {
    'code': 'EE',
    'name': 'Estonia',
    'prefix': 372
  },
  {
    'code': 'AD',
    'name': 'Andorra',
    'prefix': 376
  },
  {
    'code': 'AO',
    'name': 'Angola',
    'prefix': 244
  },
  {
    'code': 'AI',
    'name': 'Anguilla',
    'prefix': 1264
  },
  {
    'code': 'AG',
    'name': 'Antigua',
    'prefix': 1268
  },
  {
    'code': 'AT',
    'name': 'Austria',
    'prefix': 43
  },
  {
    'code': 'AU',
    'name': 'Australia',
    'prefix': 61
  },
  {
    'code': 'MO',
    'name': 'Macau',
    'prefix': 853
  },
  {
    'code': 'BB',
    'name': 'Barbados',
    'prefix': 1246
  },
  {
    'code': 'PG',
    'name': 'Papua New Guinea',
    'prefix': 675
  },
  {
    'code': 'BS',
    'name': 'The Bahamas',
    'prefix': 1242
  },
  {
    'code': 'PK',
    'name': 'Pakistan',
    'prefix': 92
  },
  {
    'code': 'PY',
    'name': 'Paraguay',
    'prefix': 595
  },
  {
    'code': 'PS',
    'name': 'Palestine',
    'prefix': 970
  },
  {
    'code': 'BH',
    'name': 'Bahrain',
    'prefix': 973
  },
  {
    'code': 'PA',
    'name': 'Panama',
    'prefix': 507
  },
  {
    'code': 'BR',
    'name': 'Brazil',
    'prefix': 55
  },
  {
    'code': 'BY',
    'name': 'Belarus',
    'prefix': 375
  },
  {
    'code': 'BM',
    'name': 'Bermuda',
    'prefix': 1441
  },
  {
    'code': 'BG',
    'name': 'Bulgaria',
    'prefix': 359
  },
  {
    'code': 'MP',
    'name': 'Northern Mariana Islands',
    'prefix': 1670
  },
  {
    'code': 'BJ',
    'name': 'Benin',
    'prefix': 229
  },
  {
    'code': 'BE',
    'name': 'Belgium',
    'prefix': 32
  },
  {
    'code': 'IS',
    'name': 'Iceland',
    'prefix': 354
  },
  {
    'code': 'PR',
    'name': 'Puerto Rico',
    'prefix': 1
  },
  {
    'code': 'PL',
    'name': 'Poland',
    'prefix': 48
  },
  {
    'code': 'BA',
    'name': 'Bosnia and Herzegovina',
    'prefix': 387
  },
  {
    'code': 'BO',
    'name': 'Bolivia',
    'prefix': 591
  },
  {
    'code': 'BZ',
    'name': 'Belize',
    'prefix': 501
  },
  {
    'code': 'PW',
    'name': 'Palau',
    'prefix': 680
  },
  {
    'code': 'BW',
    'name': 'Botswana',
    'prefix': 267
  },
  {
    'code': 'BT',
    'name': 'Bhutan',
    'prefix': 975
  },
  {
    'code': 'BF',
    'name': 'Burkina Faso',
    'prefix': 226
  },
  {
    'code': 'BI',
    'name': 'Burundi',
    'prefix': 257
  },
  {
    'code': 'KP',
    'name': 'North Korea',
    'prefix': 850
  },
  {
    'code': 'GQ',
    'name': 'Equatorial Guinea',
    'prefix': 240
  },
  {
    'code': 'DK',
    'name': 'Denmark',
    'prefix': 45
  },
  {
    'code': 'DE',
    'name': 'Germany',
    'prefix': 49
  },
  {
    'code': 'TL',
    'name': 'Timor-Leste',
    'prefix': 670
  },
  {
    'code': 'TG',
    'name': 'Togo',
    'prefix': 228
  },
  {
    'code': 'DM',
    'name': 'Dominica',
    'prefix': 1767
  },
  {
    'code': 'DO',
    'name': 'Dominican Republic',
    'prefix': 1809
  },
  {
    'code': 'RU',
    'name': 'Russia',
    'prefix': 7
  },
  {
    'code': 'EC',
    'name': 'Ecuador',
    'prefix': 593
  },
  {
    'code': 'ER',
    'name': 'Eritrea',
    'prefix': 291
  },
  {
    'code': 'FR',
    'name': 'France',
    'prefix': 33
  },
  {
    'code': 'FO',
    'name': 'Faroe Islands',
    'prefix': 298
  },
  {
    'code': 'PF',
    'name': 'French Polynesia',
    'prefix': 689
  },
  {
    'code': 'GF',
    'name': 'French Guiana',
    'prefix': 594
  },
  {
    'code': 'PM',
    'name': 'Saint Pierre and Miquelon',
    'prefix': 508
  },
  {
    'code': 'VA',
    'name': 'Vatican City',
    'prefix': 39
  },
  {
    'code': 'PH',
    'name': 'Philippines',
    'prefix': 63
  },
  {
    'code': 'FJ',
    'name': 'Fiji',
    'prefix': 679
  },
  {
    'code': 'FI',
    'name': 'Finland',
    'prefix': 358
  },
  {
    'code': 'CV',
    'name': 'Cape Verde',
    'prefix': 238
  },
  {
    'code': 'FK',
    'name': 'Falkland Islands',
    'prefix': 500
  },
  {
    'code': 'GM',
    'name': 'The Gambia',
    'prefix': 220
  },
  {
    'code': 'CG',
    'name': 'Republic of the Congo',
    'prefix': 242
  },
  {
    'code': 'CD',
    'name': 'Democratic Republic of the Congo',
    'prefix': 243
  },
  {
    'code': 'CO',
    'name': 'Colombia',
    'prefix': 57
  },
  {
    'code': 'CR',
    'name': 'Costa Rica',
    'prefix': 506
  },
  {
    'code': 'GD',
    'name': 'Grenada',
    'prefix': 1473
  },
  {
    'code': 'GL',
    'name': 'Greenland',
    'prefix': 299
  },
  {
    'code': 'GE',
    'name': 'Georgia',
    'prefix': 995
  },
  {
    'code': 'GG',
    'name': 'Guernsey',
    'prefix': 44
  },
  {
    'code': 'CU',
    'name': 'Cuba',
    'prefix': 53
  },
  {
    'code': 'GP',
    'name': 'Guadeloupe',
    'prefix': 590
  },
  {
    'code': 'GU',
    'name': 'Guam',
    'prefix': 1671
  },
  {
    'code': 'GY',
    'name': 'Guyana',
    'prefix': 592
  },
  {
    'code': 'KZ',
    'name': 'Kazakhstan',
    'prefix': 7
  },
  {
    'code': 'HT',
    'name': 'Haiti',
    'prefix': 509
  },
  {
    'code': 'KR',
    'name': 'South Korea',
    'prefix': 82
  },
  {
    'code': 'NL',
    'name': 'Netherlands',
    'prefix': 31
  },
  {
    'code': 'BQ',
    'name': 'Bonaire, Sint Eustatius and Saba',
    'prefix': 599
  },
  {
    'code': 'ME',
    'name': 'Montenegro',
    'prefix': 382
  },
  {
    'code': 'HN',
    'name': 'Honduras',
    'prefix': 504
  },
  {
    'code': 'KI',
    'name': 'Kiribati',
    'prefix': 686
  },
  {
    'code': 'DJ',
    'name': 'Djibouti',
    'prefix': 253
  },
  {
    'code': 'KG',
    'name': 'Kyrgyzstan',
    'prefix': 996
  },
  {
    'code': 'GN',
    'name': 'Guinea',
    'prefix': 224
  },
  {
    'code': 'GW',
    'name': 'Guinea-Bissau',
    'prefix': 245
  },
  {
    'code': 'CA',
    'name': 'Canada',
    'prefix': 1
  },
  {
    'code': 'GH',
    'name': 'Ghana',
    'prefix': 233
  },
  {
    'code': 'GA',
    'name': 'Gabon',
    'prefix': 241
  },
  {
    'code': 'KH',
    'name': 'Cambodia',
    'prefix': 855
  },
  {
    'code': 'CZ',
    'name': 'Czech Republic',
    'prefix': 420
  },
  {
    'code': 'ZW',
    'name': 'Zimbabwe',
    'prefix': 263
  },
  {
    'code': 'CM',
    'name': 'Cameroon',
    'prefix': 237
  },
  {
    'code': 'QA',
    'name': 'Qatar',
    'prefix': 974
  },
  {
    'code': 'KY',
    'name': 'Cayman Islands',
    'prefix': 1345
  },
  {
    'code': 'KM',
    'name': 'Comoros',
    'prefix': 269
  },
  {
    'code': 'XK',
    'name': 'Kosovo',
    'prefix': 381
  },
  {
    'code': 'CI',
    'name': 'Côte d\'Ivoire',
    'prefix': 225
  },
  {
    'code': 'KW',
    'name': 'Kuwait',
    'prefix': 965
  },
  {
    'code': 'HR',
    'name': 'Croatia',
    'prefix': 385
  },
  {
    'code': 'KE',
    'name': 'Kenya',
    'prefix': 254
  },
  {
    'code': 'CK',
    'name': 'Cook Islands',
    'prefix': 682
  },
  {
    'code': 'CW',
    'name': 'Curaçao',
    'prefix': 599
  },
  {
    'code': 'LV',
    'name': 'Latvia',
    'prefix': 371
  },
  {
    'code': 'LS',
    'name': 'Lesotho',
    'prefix': 266
  },
  {
    'code': 'LA',
    'name': 'Laos',
    'prefix': 856
  },
  {
    'code': 'LB',
    'name': 'Lebanon',
    'prefix': 961
  },
  {
    'code': 'LT',
    'name': 'Lithuania',
    'prefix': 370
  },
  {
    'code': 'LR',
    'name': 'Liberia',
    'prefix': 231
  },
  {
    'code': 'LY',
    'name': 'Libya',
    'prefix': 218
  },
  {
    'code': 'LI',
    'name': 'Liechtenstein',
    'prefix': 423
  },
  {
    'code': 'RE',
    'name': 'Réunion',
    'prefix': 262
  },
  {
    'code': 'LU',
    'name': 'Luxembourg',
    'prefix': 352
  },
  {
    'code': 'RW',
    'name': 'Rwanda',
    'prefix': 250
  },
  {
    'code': 'RO',
    'name': 'Romania',
    'prefix': 40
  },
  {
    'code': 'MG',
    'name': 'Madagascar',
    'prefix': 261
  },
  {
    'code': 'IM',
    'name': 'Isle Of Man',
    'prefix': 44
  },
  {
    'code': 'MV',
    'name': 'Maldives',
    'prefix': 960
  },
  {
    'code': 'MT',
    'name': 'Malta',
    'prefix': 356
  },
  {
    'code': 'MW',
    'name': 'Malawi',
    'prefix': 265
  },
  {
    'code': 'MY',
    'name': 'Malaysia',
    'prefix': 60
  },
  {
    'code': 'ML',
    'name': 'Mali',
    'prefix': 223
  },
  {
    'code': 'MK',
    'name': 'Macedonia',
    'prefix': 389
  },
  {
    'code': 'MH',
    'name': 'Marshall Islands',
    'prefix': 692
  },
  {
    'code': 'MQ',
    'name': 'Martinique',
    'prefix': 596
  },
  {
    'code': 'YT',
    'name': 'Mayotte',
    'prefix': 262
  },
  {
    'code': 'MU',
    'name': 'Mauritius',
    'prefix': 230
  },
  {
    'code': 'MR',
    'name': 'Mauritania',
    'prefix': 222
  },
  {
    'code': 'US',
    'name': 'United States',
    'prefix': 1
  },
  {
    'code': 'AS',
    'name': 'American Samoa',
    'prefix': 1684
  },
  {
    'code': 'VI',
    'name': 'US Virgin Islands',
    'prefix': 1340
  },
  {
    'code': 'MN',
    'name': 'Mongolia',
    'prefix': 976
  },
  {
    'code': 'MS',
    'name': 'Montserrat',
    'prefix': 1664
  },
  {
    'code': 'BD',
    'name': 'Bangladesh',
    'prefix': 880
  },
  {
    'code': 'PE',
    'name': 'Peru',
    'prefix': 51
  },
  {
    'code': 'FM',
    'name': 'Federated States of Micronesia',
    'prefix': 691
  },
  {
    'code': 'MM',
    'name': 'Myanmar',
    'prefix': 95
  },
  {
    'code': 'MD',
    'name': 'Moldova',
    'prefix': 373
  },
  {
    'code': 'MA',
    'name': 'Morocco',
    'prefix': 212
  },
  {
    'code': 'MC',
    'name': 'Monaco',
    'prefix': 377
  },
  {
    'code': 'MZ',
    'name': 'Mozambique',
    'prefix': 258
  },
  {
    'code': 'MX',
    'name': 'Mexico',
    'prefix': 52
  },
  {
    'code': 'NA',
    'name': 'Namibia',
    'prefix': 264
  },
  {
    'code': 'ZA',
    'name': 'South Africa',
    'prefix': 27
  },
  {
    'code': 'SS',
    'name': 'South Sudan',
    'prefix': 211
  },
  {
    'code': 'NR',
    'name': 'Nauru',
    'prefix': 674
  },
  {
    'code': 'NI',
    'name': 'Nicaragua',
    'prefix': 505
  },
  {
    'code': 'NP',
    'name': 'Nepal',
    'prefix': 977
  },
  {
    'code': 'NE',
    'name': 'Niger',
    'prefix': 227
  },
  {
    'code': 'NG',
    'name': 'Nigeria',
    'prefix': 234
  },
  {
    'code': 'NU',
    'name': 'Niue',
    'prefix': 683
  },
  {
    'code': 'NO',
    'name': 'Norway',
    'prefix': 47
  },
  {
    'code': 'NF',
    'name': 'Norfolk Island',
    'prefix': 672
  },
  {
    'code': 'PT',
    'name': 'Portugal',
    'prefix': 351
  },
  {
    'code': 'JP',
    'name': 'Japan',
    'prefix': 81
  },
  {
    'code': 'SE',
    'name': 'Sweden',
    'prefix': 46
  },
  {
    'code': 'CH',
    'name': 'Switzerland',
    'prefix': 41
  },
  {
    'code': 'SV',
    'name': 'El Salvador',
    'prefix': 503
  },
  {
    'code': 'WS',
    'name': 'Samoa',
    'prefix': 685
  },
  {
    'code': 'RS',
    'name': 'Serbia',
    'prefix': 381
  },
  {
    'code': 'SL',
    'name': 'Sierra Leone',
    'prefix': 232
  },
  {
    'code': 'SN',
    'name': 'Senegal',
    'prefix': 221
  },
  {
    'code': 'CY',
    'name': 'Cyprus',
    'prefix': 357
  },
  {
    'code': 'SC',
    'name': 'Seychelles',
    'prefix': 248
  },
  {
    'code': 'SA',
    'name': 'Saudi Arabia',
    'prefix': 966
  },
  {
    'code': 'BL',
    'name': 'Saint Barthélemy',
    'prefix': 590
  },
  {
    'code': 'ST',
    'name': 'Sao Tome and Principe',
    'prefix': 239
  },
  {
    'code': 'SH',
    'name': 'Saint Helena',
    'prefix': 290
  },
  {
    'code': 'KN',
    'name': 'Saint Kitts and Nevis',
    'prefix': 1869
  },
  {
    'code': 'LC',
    'name': 'Saint Lucia',
    'prefix': 1758
  },
  {
    'code': 'MF',
    'name': 'Saint Martin',
    'prefix': 590
  },
  {
    'code': 'SX',
    'name': 'Sint Maarten',
    'prefix': 599
  },
  {
    'code': 'SM',
    'name': 'San Marino',
    'prefix': 378
  },
  {
    'code': 'VC',
    'name': 'Saint Vincent and the Grenadines',
    'prefix': 1784
  },
  {
    'code': 'LK',
    'name': 'Sri Lanka',
    'prefix': 94
  },
  {
    'code': 'SK',
    'name': 'Slovakia',
    'prefix': 421
  },
  {
    'code': 'SI',
    'name': 'Slovenia',
    'prefix': 386
  },
  {
    'code': 'SZ',
    'name': 'Swaziland',
    'prefix': 268
  },
  {
    'code': 'SD',
    'name': 'Sudan',
    'prefix': 249
  },
  {
    'code': 'SR',
    'name': 'Suriname',
    'prefix': 597
  },
  {
    'code': 'SB',
    'name': 'Solomon Islands',
    'prefix': 677
  },
  {
    'code': 'SO',
    'name': 'Somalia',
    'prefix': 252
  },
  {
    'code': 'TJ',
    'name': 'Tajikistan',
    'prefix': 992
  },
  {
    'code': 'TW',
    'name': 'Taiwan',
    'prefix': 886
  },
  {
    'code': 'TH',
    'name': 'Thailand',
    'prefix': 66
  },
  {
    'code': 'TZ',
    'name': 'Tanzania',
    'prefix': 255
  },
  {
    'code': 'TO',
    'name': 'Tonga',
    'prefix': 676
  },
  {
    'code': 'TC',
    'name': 'Turks and Caicos Islands',
    'prefix': 1649
  },
  {
    'code': 'TT',
    'name': 'Trinidad and Tobago',
    'prefix': 1868
  },
  {
    'code': 'TN',
    'name': 'Tunisia',
    'prefix': 216
  },
  {
    'code': 'TV',
    'name': 'Tuvalu',
    'prefix': 688
  },
  {
    'code': 'TR',
    'name': 'Turkey',
    'prefix': 90
  },
  {
    'code': 'TM',
    'name': 'Turkmenistan',
    'prefix': 993
  },
  {
    'code': 'TK',
    'name': 'Tokelau',
    'prefix': 690
  },
  {
    'code': 'WF',
    'name': 'Wallis and Futuna',
    'prefix': 681
  },
  {
    'code': 'VU',
    'name': 'Vanuatu',
    'prefix': 678
  },
  {
    'code': 'GT',
    'name': 'Guatemala',
    'prefix': 502
  },
  {
    'code': 'VE',
    'name': 'Venezuela',
    'prefix': 58
  },
  {
    'code': 'BN',
    'name': 'Brunei',
    'prefix': 673
  },
  {
    'code': 'UG',
    'name': 'Uganda',
    'prefix': 256
  },
  {
    'code': 'UA',
    'name': 'Ukraine',
    'prefix': 380
  },
  {
    'code': 'UY',
    'name': 'Uruguay',
    'prefix': 598
  },
  {
    'code': 'UZ',
    'name': 'Uzbekistan',
    'prefix': 998
  },
  {
    'code': 'GR',
    'name': 'Greece',
    'prefix': 30
  },
  {
    'code': 'ES',
    'name': 'Spain',
    'prefix': 34
  },
  {
    'code': 'EH',
    'name': 'Western Sahara',
    'prefix': 212
  },
  {
    'code': 'HK',
    'name': 'Hong Kong',
    'prefix': 852
  },
  {
    'code': 'SG',
    'name': 'Singapore',
    'prefix': 65
  },
  {
    'code': 'NC',
    'name': 'New Caledonia',
    'prefix': 687
  },
  {
    'code': 'NZ',
    'name': 'New Zealand',
    'prefix': 64
  },
  {
    'code': 'HU',
    'name': 'Hungary',
    'prefix': 36
  },
  {
    'code': 'SY',
    'name': 'Syria',
    'prefix': 963
  },
  {
    'code': 'JM',
    'name': 'Jamaica',
    'prefix': 1876
  },
  {
    'code': 'AM',
    'name': 'Armenia',
    'prefix': 374
  },
  {
    'code': 'YE',
    'name': 'Yemen',
    'prefix': 967
  },
  {
    'code': 'IQ',
    'name': 'Iraq',
    'prefix': 964
  },
  {
    'code': 'IR',
    'name': 'Iran',
    'prefix': 98
  },
  {
    'code': 'IL',
    'name': 'Israel',
    'prefix': 972
  },
  {
    'code': 'IT',
    'name': 'Italy',
    'prefix': 39
  },
  {
    'code': 'IN',
    'name': 'India',
    'prefix': 91
  },
  {
    'code': 'ID',
    'name': 'Indonesia',
    'prefix': 62
  },
  {
    'code': 'GB',
    'name': 'United Kingdom',
    'prefix': 44
  },
  {
    'code': 'VG',
    'name': 'British Virgin Islands',
    'prefix': 1284
  },
  {
    'code': 'IO',
    'name': 'British Indian Ocean Territory',
    'prefix': 246
  },
  {
    'code': 'JO',
    'name': 'Jordan',
    'prefix': 962
  },
  {
    'code': 'VN',
    'name': 'Vietnam',
    'prefix': 84
  },
  {
    'code': 'ZM',
    'name': 'Zambia',
    'prefix': 260
  },
  {
    'code': 'JE',
    'name': 'Jersey',
    'prefix': 44
  },
  {
    'code': 'TD',
    'name': 'Chad',
    'prefix': 235
  },
  {
    'code': 'GI',
    'name': 'Gibraltar',
    'prefix': 350
  },
  {
    'code': 'CL',
    'name': 'Chile',
    'prefix': 56
  },
  {
    'code': 'CF',
    'name': 'Central African Republic',
    'prefix': 236
  },
  {
    'code': 'CN',
    'name': 'China',
    'prefix': 86
  }
];

const getCountryPrefixes = (formatMessage, cdnUrl) => {
  const messages = getMessages();
  return countryPrefixes.map((country) => {
    const code = country.code;
    const countryMsg = messages.countries[code];
    const flag = `${cdnUrl}/assets/icons/flags/${code.toLowerCase()}.svg`;
    const prefix = `+${country.prefix}`;
    try {
      return {
        code: country.code,
        name: formatMessage(countryMsg),
        prefix: prefix,
        src: flag
      };
    } catch (e) {
      return {
        code: country.code,
        name: country.name,
        prefix: prefix,
        src: flag
      };
    }
  });
};

const useFlagsWithPrefix = (cdnUrl) => {
  const intl = useIntl();
  const prefixFlags = useMemo(() => {
    const locale = ['uk', 'en'].includes(intl.locale) ? 'gb' : intl.locale;
    const cp = getCountryPrefixes(intl.formatMessage, cdnUrl);
    const flagsWithPrefix = cp.sort((a, b) => a.name.localeCompare(b.name));
    const selectedCountry = flagsWithPrefix.find((country) => country.code.toLowerCase() === locale);
    return { selectedCountry, flagsWithPrefix };
  }, [intl]);
  return prefixFlags;
};

export {
  getCountryPrefixes, countryPrefixes, useFlagsWithPrefix
};
