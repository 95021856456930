import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';
import ValidatedInput from '../ValidatedInput';
import * as leadHelper from '../../utils/api/leadHelper';
import { cookiesLib } from '../../utils/cookies';
import { listingPropTypes } from '../../utils/commonPropTypes';
import {
  addEcommerceTracking,
  addProduct,
  addProductClick,
  addLead,
  addProLead,
  clearEcommerceData,
  trackContactFormSubmit,
  trackContactManufacturerFormSubmit
} from '../../store/actions/dataLayer';
import { showAdditionalLeadsModal } from '../../store/actions';
import {
  getLeadTypes,
  getBoatLeadTypeOptions,
  getPartyLeadTypeOptions,
  getDealerLeadTypeOptions
} from '../../utils/leadsHelper';
import { getCountries } from '../../utils/countryHelpers';
import { getCurrentLocale } from '../../utils/language';
import { getConfig, PortalConfigContext } from '../../config/portal';
import * as storage from '../../utils/storage';
import './styles.css';
import './details.css';
import { getMessages } from '../../tppServices/translations/messages';


const labelTexts = (t) => {
  const messages = getMessages();
  return {
    name: t(messages.contactForm.firstName),
    email: t(messages.contactForm.email),
    phone: t(messages.contactForm.phone),
    comments: t(messages.contactForm.comments),
    firstName: t(messages.contactForm.firstName),
    zip: t(messages.postalCode),
    country: t(messages.contactForm.country)
  };
};

const placeholderTexts = (t) => {
  const messages = getMessages();
  return {
    name: t(messages.contactForm.fullName),
    email: t(messages.contactForm.yourEmail),
    phone: t(messages.contactForm.yourPhone),
    comments: t(messages.contactForm.comments),
    firstName: t(messages.contactForm.firstName),
    country: t(messages.contactForm.selectCountry),
    zip: t(messages.postalCode)
  };
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    const showCountry = this.props.showCountry;
    this.currentLocaleUpper = String(getCurrentLocale()).toUpperCase();
    const countryVal = showCountry ? this.currentLocaleUpper : '';
    this.state = {
      formValid: {
        name: false,
        email: false,
        phone: true,
        comments: !!this.props.prePopulatedText,
        zip: !this.props.showZipCode,
      },
      formFields: {
        name: '',
        email: '',
        phone: '',
        comments: this.props.prePopulatedText || '',
        zip: '',
        country: countryVal
      },
      formSubmitted: false,
      scrollLocked: false,
      validationErrors: false,
      showSuccess: false,
      showError: false,
      preventScrollContact: false,
      isOemManufactuer: this.props.isOemManufactuer || false,
      isOemDealer: this.props.isOemDealer || false
    };
    this.updateField = this.updateField.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.containerRef = React.createRef();
  }

  postSubmit = {
    [getBoatLeadTypeOptions().type]: () => {
      const {
        itemId,
        data: { boatClass, length, listingType, make, subdivision } = {}
      } = this.props;
      if (this.props.productTrack) {
        this.props.addProduct(itemId, boatClass, make, length, subdivision);
        this.props.addProductClick(itemId, listingType);
      }
      this.props.addLead(itemId, getBoatLeadTypeOptions().contactFormLeadType);
      if (this.props.mode === ContactForm.MODES.results) {
        this.props.addEcommerceTracking(itemId);
      }
    },
    [getPartyLeadTypeOptions().type]: () => {
      if (this.props.isOemManufactuer) {
        const emailLead = 'email lead';
        const {
          itemId,
          data: { boatClass, length, listingType, make, subdivision } = {}
        } = this.props;
        /* istanbul ignore next */
        if (this.props.productTrack) {
          this.props.addProduct(itemId, boatClass, make, length, subdivision);
          this.props.addProductClick(itemId, listingType);
        }
        this.props.addLead(itemId, emailLead);
        /* istanbul ignore next */
        if (this.props.mode === ContactForm.MODES.results) {
          this.props.addEcommerceTracking(itemId);
        }
      } else {
        const { itemId, data } = this.props;

        if (data && data.listingType) {
          this.props.addProductClick(itemId, data.listingType);
        }
        this.props.addProLead({ id: 'pro send email', partyId: itemId });
      }
    },
    [getDealerLeadTypeOptions().type]: () => {
      const {
        itemId,
        data: { boatClass, length, listingType, make, subdivision } = {}
      } = this.props;
      /* istanbul ignore next */
      if (this.props.productTrack) {
        this.props.addProduct(itemId, boatClass, make, length, subdivision);
        this.props.addProductClick(itemId, listingType);
      }
      this.props.addLead(
        itemId,
        getDealerLeadTypeOptions().contactFormLeadType
      );
      /* istanbul ignore next */
      if (this.props.mode === ContactForm.MODES.results) {
        this.props.addEcommerceTracking(itemId);
      }
    }
  };

  componentDidMount() {
    if (this.props.mode === ContactForm.MODES.modal) {
      document.body.classList.toggle('noscroll', this.props.open);
    }
    this.setState({
      preventScrollContact: storage.getSessionItem('preventScrollContact')
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return {
      showedMessage:
        (prevState.showSuccess || prevState.showError) && prevProps.open
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null && snapshot.showedMessage) {
      this.setState({
        showSuccess: false,
        showError: false
      });
    }
  }

  updateField(field, valid, value) {
    if (valid) {
      this.setState((prevState) => ({
        formValid: {
          ...prevState.formValid,
          [field]: valid
        },
        formFields: {
          ...prevState.formFields,
          [field]: value
        }
      }));
    } else {
      this.setState((prevState) => ({
        formValid: {
          ...prevState.formValid,
          [field]: valid
        }
      }));
    }
  }

  formIsInvalid() {
    const invalid = Object.values(this.state.formValid).filter(
      (value) => !value
    );
    return invalid.length > 0;
  }

  getLeadSource() {
    const adsLeadSource = this.context.ads.leadSource;
    if (this.props.leadSourceOverride) {
      return this.props.leadSourceOverride;
    }
    if (this.state.isOemDealer || this.state.isOemManufactuer) {
      return get(this.context, 'pages.details.leads.oemLeadSourceCode');
    }

    if (this.props?.origin) {
      return `${adsLeadSource}-${this.props.origin}`;
    }

    return adsLeadSource;
  }

  async doSubmit(event) {
    const { itemId, leadType, listing } = this.props;
    event.preventDefault();
    if (this.formIsInvalid()) {
      this.setState({ validationErrors: true });
    } else {
      this.setState({ formSubmitted: true });
      const leadData = {
        id: itemId,
        source: this.getLeadSource(),
        type: leadType,
        formFields: this.state.formFields
      };
      leadData.gaClientID = cookiesLib.get('_ga');

      if (this.props.isOemDealer) {
        leadData.id = this.props.partyId;
        leadData.imtId = this.props.itemId;
      }
      if (this.props.copyOem) {
        leadData.copyOem = this.props.copyOem;
      }
      if (this.props.oemId) {
        leadData.oemId = this.props.oemId;
      }
      if (this.props.brandId) {
        leadData.brandId = this.props.brandId;
      }
      if (leadType === getDealerLeadTypeOptions().type) {
        leadData.hideListings = true;
      }

      try {
        const resp = await leadHelper.sendLead(leadData);

        if (resp.status === 200) {
          this.postSubmit[leadType]();
          if (this.props.contactSubmitTrack) {
            if (this.props.isOemManufactuer) {
              this.props.clearEcommerceData();
              this.props.trackContactManufacturerFormSubmit(leadData.id);
            } else {
              this.props.trackContactFormSubmit({
                'leadId': get(resp, 'data.id')
              });
            }
          }
          this.setState({
            validationErrors: false,
            showSuccess: true,
            showError: false
          });
          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
          if (
            (leadType === getBoatLeadTypeOptions().type ||
              leadType === getDealerLeadTypeOptions().type) &&
            get(getConfig(), 'supports.additionalLeadsModal')
          ) {
            this.props.showAdditionalLeadsModal(leadData);
          }
          if (window.kruxEmail) {
            window.kruxEmail();
          }
          if (
            get(getConfig(), 'supports.permutive', false) &&
            get(window, 'permutiveHelper.submitLead')
          ) {
            window.permutiveHelper.submitLead('emailLead', listing.id);
          }
        } else {
          this.setState({
            formSubmitted: false,
            validationErrors: false,
            showSuccess: false,
            showError: true
          });
        }
      } catch (error) {
        this.setState({
          formSubmitted: false,
          validationErrors: false,
          showSuccess: false,
          showError: true
        });
      }
    }
  }

  onClose = () => {
    if (this.props.mode === ContactForm.MODES.modal) {
      document.body.classList.toggle('noscroll', false);
    }
    this.props.onClose();
  };

  handleInnerClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      mode,
      intl: { formatMessage }
    } = this.props;
    const t = formatMessage;
    const messages = getMessages();
    const title = t(messages.contactForm.title);
    return (
      <div
        id="contact-form"
        className={`contact-form-container-${mode}`}
        onClick={this.onClose}
      >
        <div
          id={this.props.id}
          className={classnames('contact-form', `contact-form-${mode}`, {
            open: this.props.open
          })}
          ref={this.containerRef}
          onClick={this.handleInnerClick}
        >
          <button
            type="button"
            // eslint-disable-next-line react/no-string-refs
            ref="contactClose"
            className="contact-close"
            onClick={this.onClose}
            aria-label="Close"
          >
            <span className="visuallyhidden">
              {formatMessage(messages.contactForm.close)}
            </span>
          </button>
          <div
            className={classnames('main-form', {
              hidden: this.state.showSuccess
            })}
          >
            <h2 className="title">{title}</h2>
            <div
              className={classnames('message-error', {
                hidden: !this.state.validationErrors
              })}
            >
              {`* ${formatMessage(messages.contactForm.formError)}`}
            </div>
            <form
              aria-label={title}
              onSubmit={this.doSubmit}
              className={classnames('form', {
                error: this.state.validationErrors
              })}
            >
              <input type="hidden" id="listing-id" name="listing-id" value="" />
              <input type="hidden" id="imt-id" name="imt-id" value="" />
              <input type="hidden" id="zipCode" name="zipCode" value="" />

              {this.renderInputs()}

              {this.props.showTermsAndPrivacy && (
                <p className="terms-privacy">
                  {t(messages.searchAlerts.termsAndConditions.disclaimer)}&nbsp;
                  <a
                    href={t(messages.searchAlerts.termsAndConditions.url)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t(messages.searchAlerts.termsAndConditions.name)}
                  </a>
                  &nbsp;&amp;&nbsp;
                  <a
                    href={t(messages.footer.privacyPolicy.url)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {capitalize(t(messages.footer.privacyPolicy.name))}
                  </a>
                </p>
              )}

              <button type="submit" className="btn-action" disabled={this.state.formSubmitted}>
                {this.props.contactButtonText ||
                  formatMessage(messages.contactForm.contactButton)}
              </button>
            </form>
          </div>
          {this.state.showSuccess && (
            <div
              className={classnames('message-sent', {
                hidden: !this.state.showSuccess
              })}
            >
              <div className="success-alert">
                <div className="message-box">
                  <p className="message-title">
                    {formatMessage(messages.contactForm.messageSentTitle)}
                  </p>
                  <p>{formatMessage(messages.contactForm.messageSent)}</p>
                </div>
              </div>
            </div>
          )}
          {this.state.showError && (
            <div
              className={classnames('message-error', {
                hidden: !this.state.showError
              })}
            >
              <div className="error-alert">
                <div className="message-box">
                  <p className="message-title">
                    {formatMessage(messages.contactForm.messageErrorTitle)}
                  </p>
                  <p>{formatMessage(messages.contactForm.messageError)}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderInputs() {
    const {
      intl: { messages: m, formatMessage: t}
    } = this.props;
    const hiddenLabel = this.props.mode === ContactForm.MODES.results;
    const idPrefix = this.props.id;
    const labelText = labelTexts(t);
    const placeholderText = placeholderTexts(t);
    let inputs = [
      {
        inputName: 'name',
        render: (index) => (
          <ValidatedInput
            type="text"
            name="name"
            id={`${idPrefix}-name`}
            key={index}
            tabindex={index + 1}
            placeholderText={placeholderText.name}
            labelText={labelText.name}
            onChange={this.updateField}
            showError={this.state.validationErrors}
            validation="fullName"
            mode={this.props.mode}
            hiddenLabel={hiddenLabel}
          />
        )
      },
      {
        inputName: 'email',
        render: (index) => (
          <ValidatedInput
            type="text"
            name="email"
            id={`${idPrefix}-email`}
            key={index}
            tabindex={index + 1}
            placeholderText={placeholderText.email}
            labelText={labelText.email}
            onChange={this.updateField}
            showError={this.state.validationErrors}
            validation="email"
            mode={this.props.mode}
            hiddenLabel={hiddenLabel}
          />
        )
      },
      {
        inputName: 'phone',
        render: (index) => (
          <ValidatedInput
            type="tel"
            name="phone"
            id={`${idPrefix}-phone`}
            key={index}
            tabindex={index + 1}
            placeholderText={placeholderText.phone}
            labelText={labelText.phone}
            onChange={this.updateField}
            showError={this.state.validationErrors}
            validation="phone"
            mode={this.props.mode}
            hiddenLabel={hiddenLabel}
          />
        )
      },
      {
        inputName: 'comments',
        render: (index) => (
          <ValidatedInput
            type="textarea"
            name="comments"
            id={`${idPrefix}-comments`}
            key={index}
            tabindex={index + 1}
            placeholderText={placeholderText.comments}
            labelText={labelText.comments}
            onChange={this.updateField}
            showError={this.state.validationErrors}
            validation="text"
            defaultValue={this.props.prePopulatedText}
            mode={this.props.mode}
            hiddenLabel={hiddenLabel}
          />
        )
      },
      {
        inputName: 'firstName',
        render: (index) => (
          <ValidatedInput
            type="text"
            name="fname"
            id={`${idPrefix}-fname`}
            key={index}
            tabindex={index + 1}
            labelText={labelText.firstName}
            hidden
            onChange={this.updateField}
          />
        )
      }
    ];

    if (this.props.showZipCode) {
      inputs.push({
        inputName: 'zip',
        render: (index) => (
          <ValidatedInput
            type="text"
            name="zip"
            id="zip"
            key={index}
            tabindex={index + 1}
            labelText={labelText.zip}
            placeholderText={placeholderText.zip}
            onChange={this.updateField}
            showError={this.state.validationErrors}
            mode={this.props.mode}
            hiddenLabel={hiddenLabel}
            validation="zip"
          />
        )
      });
    }

    if (this.props.showCountry) {
      inputs.push({
        inputName: 'country',
        render: (index) => (
          <ValidatedInput
            type="select"
            name="country"
            id="country"
            key={index}
            tabindex={index + 1}
            labelText={labelText.country}
            placeholderText={placeholderText.country}
            defaultValue={this.currentLocaleUpper}
            onChange={this.updateField}
            showError={this.state.validationErrors}
            mode={this.props.mode}
            hiddenLabel={hiddenLabel}
            validation="country"
            data={getCountries(m)}
          />
        )
      });
    }

    if (this.props.hidePhone) {
      inputs = inputs.filter((input) => input.inputName !== 'phone');
    }

    if (this.props.orderInputs) {
      inputs = sortBy(inputs, ({ inputName }) =>
        this.props.orderInputs.indexOf(inputName)
      );
    }

    return inputs.map((input, index) => input.render(index));
  }
}

ContactForm.MODES = {
  details: 'details',
  results: 'results',
  modal: 'modal'
};

ContactForm.TYPES = {
  boat: getBoatLeadTypeOptions().type,
  party: getPartyLeadTypeOptions().type,
  dealer: getDealerLeadTypeOptions().type
};

ContactForm.defaultProps = {
  leadType: ContactForm.TYPES.boat,
  contactSubmitTrack: false,
  id: 'results-contact-form',
  listing: {},
  mode: ContactForm.MODES.results,
  open: false,
  productTrack: true,
  showZipCode: false,
  showCountry: false
};

ContactForm.propTypes = {
  leadType: PropTypes.oneOf(getLeadTypes()),
  addLead: PropTypes.func,
  addEcommerceTracking: PropTypes.func,
  addProduct: PropTypes.func,
  addProductClick: PropTypes.func,
  addProLead: PropTypes.func,
  contactButtonText: PropTypes.string,
  contactSubmitTrack: PropTypes.bool,
  leadSourceOverride: PropTypes.string,
  data: PropTypes.shape({
    boatClass: PropTypes.string,
    make: PropTypes.string,
    listingType: PropTypes.string,
    length: PropTypes.string,
    subdivision: PropTypes.string
  }),
  id: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    messages: PropTypes.object
  }).isRequired,
  itemId: PropTypes.number,
  mode: PropTypes.oneOf(Object.keys(ContactForm.MODES)),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  orderInputs: PropTypes.arrayOf(PropTypes.string),
  prePopulatedText: PropTypes.string,
  productTrack: PropTypes.bool,
  trackContactFormSubmit: PropTypes.func,
  clearEcommerceData: PropTypes.func,
  trackContactManufacturerFormSubmit: PropTypes.func,
  showAdditionalLeadsModal: PropTypes.func,
  origin: PropTypes.string,
  scrolled: PropTypes.bool,
  listing: listingPropTypes,
  isOemManufactuer: PropTypes.bool,
  isOemDealer: PropTypes.bool,
  copyOem: PropTypes.bool,
  oemId: PropTypes.number,
  brandId: PropTypes.number,
  showZipCode: PropTypes.bool,
  onSuccess: PropTypes.func,
  partyId: PropTypes.string,
  showCountry: PropTypes.bool,
  showTermsAndPrivacy: PropTypes.bool,
  hidePhone: PropTypes.bool
};

ContactForm.contextType = PortalConfigContext;

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      addEcommerceTracking,
      addProduct,
      addProductClick,
      addLead,
      addProLead,
      trackContactFormSubmit,
      clearEcommerceData,
      trackContactManufacturerFormSubmit,
      showAdditionalLeadsModal
    },
    dispatch
  )
)(injectIntl(ContactForm));
